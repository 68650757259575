import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { signIn } from 'next-auth/react';
import { CredentialProvidersEnum } from 'abi-people-platform-auth-client-lib';

import { withSSRUnauth } from '~/app/hocs';

export default function Redirect() {
  const hasRedirectedToSignIn = useRef(false);
  const router = useRouter();

  const errorMessage = router.query.error;
  const callbackUrl = router.query.callbackUrl ?? '/';

  useEffect(() => {
    if (errorMessage) {
      router.push('/auth/error', { query: { error: errorMessage } });
    } else if (
      typeof callbackUrl === 'string' &&
      !hasRedirectedToSignIn.current
    ) {
      hasRedirectedToSignIn.current = true;

      const cbUrlObj = new URL(callbackUrl);

      const callbackUrlWithLocale = `${cbUrlObj.origin}/${cbUrlObj.pathname}${cbUrlObj.search}`;

      signIn(CredentialProvidersEnum.AZURE_AD, {
        callbackUrl: callbackUrlWithLocale,
      });
    }
  }, [callbackUrl, errorMessage, router]);

  return null;
}

export const getServerSideProps = withSSRUnauth(async () => ({ props: {} }));

Redirect.unauth = true;
